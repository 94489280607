export const TOGGLE_LOADING = 'TOGGLE_LOADING';
export const RECENT_TRANSACTION_COUNT = 25;
export const CURRENCIES = {
  0: 'ALL',
  1: 'EUR'
};

export const CRYPTO_CURRENCIES = {
  0: 'ALL',
  1: 'BTC',
  2: 'LTC',
  3: 'XRP',
  4: 'BCH',
  5: 'ETH',
};

export const ORDER_TYPES1 = {
  0: 'ALL',
  'WITHDRAW': 'WITHDRAW',
  'FIXED_DEPOSIT': 'FIXED DEPOSIT',
  'DYNAMIC_DEPOSIT': 'DYNAMIC DEPOSIT',
};

export const ORDER_TYPES = [
  {
    id: 0,
    name: 'ALL',
    value: 'ALL',
  },
  {
    id: 1,
    name: 'WITHDRAW',
    value: 'WITHDRAW',
  },
  {
    id: 2,
    name: 'COIN STORE',
    value: 'COIN_STORE',
  },
  {
    id: 3,
    name: 'COIN WITHDRAW',
    value: 'COIN_WITHDRAW',
  },
  {
    id: 4,
    name: 'RECEIVE PAYMENT',
    value: 'PAYMENT',
  },
  {
    id: 5,
    name: 'RECEIVE DEPOSIT',
    value: 'DEPOSIT',
  },
  {
    id: 6,
    name: 'BUY',
    value: 'BUY',
  },
  {
    id: 7,
    name: 'SELL',
    value: 'SELL',
  }
]

export const ORDER_STATUS = [
  {
    value: 0, name: 'ALL'
  },
  {
    value: 1, name: 'CREATED'
  },
  {
    value: 2, name: 'PENDING'
  },
  {
    value: 3, name: 'COMPLETED'
  },
  {
    value: 4, name: 'EXPIRED'
  },
  {
    value: 5, name: 'FAILED'
  },
  {
    value: 6, name: 'REFUNDED'
  },
  {
    value: 7, name: 'REFUNDABLE'
  },
  {
    value: 8, name: 'ACCEPTED'
  },
  {
    value: 9, name: 'CANCELLED'
  },
  {
    value: 10, name: 'REFUND PROCESSING'
  }
];

export const ORDER_DEFAULT_FILTERS = {
  orderId: null,
  searchText: null,
  txId: null,
  cryptoCurrency: "ALL",
  minOrderAmount: null,
  maxOrderAmount: null,
  minCryptoCurrencyAmount: null,
  maxCryptoCurrencyAmount: null,
  currency: "ALL",
  type: "ALL",
  status: "ALL",
  fromDate: null,
  toDate: null
};


export const GROUP_PRIVILAGES = [
         {
           id: 0,
           name: "PANEL_ADMIN",
           display: "ADMIN",
           privilages: [
             {
               name: "ACCEPT_REFUND_ORDERS",
               display: "ACCEPT REFUND ORDERS"
             },
             {
               name: "MAKE_PAYMENT",
               display: "MAKE PAYMENT"
             },
             {
               name: "WITHDRAW_REQUEST_MANAGEMENT",
               display: "CREATE WITHDRAW REQUEST"
             },
             {
               name: "EXPORT_ORDER_LIST",
               display: "EXPORT ORDER LIST"
             },
             {
               name: "DISPLAYING_SUPPORT",
               display: "DISPLAYING SUPPORT"
             },
             {
               name: "BANK_ACCOUNT_MANAGEMENT",
               display: "MANAGING BANK ACCOUNT"
             },
             {
               name: "USER_MANAGEMENT_ACTIONS",
               display: "USER MANAGEMENT ACTIONS"
             },
             {
               name: "DISPLAY_USER_LOGS",
               display: "DISPLAY USER LOGS"
             },
             {
               name: "API_MANAGEMENT",
               display: "API MANAGEMENT"
             },
             {
               name: "TRADE_COIN",
               display: "TRADE COIN"
             },
             {
               name: "RECEIVE_PAYMENT",
               display: "RECEIVE PAYMENT"
             },
             {
              name: "DEPOSIT",
              display: "DEPOSIT"
            },
            {
              name: "CURRENCY_MANAGEMENT",
              display: "CURRENCY MANAGEMENT"
            },
            {
              name: "SUB_MERCHANT_VIEW",
              display: "SUBMERCHANT VIEW"
            },
            {
              name: "PAYMENT_SETTINGS",
              display: "PAYMENT SETTINGS"
            },
            {
              name: "AUTO_CONVERSION",
              display: "AUTO CONVERSION"
            }
          ]
         },
         {
           id: 1,
           name: "PANEL_FINANCE",
           display: "FINANCE",
           privilages: [
             {
               name: "ACCEPT_REFUND_ORDERS",
               display: "ACCEPT REFUND ORDERS"
             },
             {
               name: "MAKE_PAYMENT",
               display: "MAKE PAYMENT"
             },
             {
               name: "WITHDRAW_REQUEST_MANAGEMENT",
               display: "CREATE WITHDRAW REQUEST"
             },
             {
               name: "EXPORT_ORDER_LIST",
               display: "EXPORT ORDER LIST"
             },
             {
               name: "DISPLAYING_SUPPORT",
               display: "DISPLAYING SUPPORT"
             },
             {
               name: "TRADE_COIN",
               display: "TRADE COIN"
             },
             {
               name: "RECEIVE_PAYMENT",
               display: "RECEIVE PAYMENT"
             }
           ]
         },
         {
           id: 2,
           name: "PANEL_USER",
           display: "USER",
           privilages: [
             {
               name: "EXPORT_ORDER_LIST",
               display: "EXPORT ORDER LIST"
             },
             {
               name: "DISPLAYING_SUPPORT",
               display: "DISPLAYING SUPPORT"
             }
           ]
         }
       ];

export const NONE_AUTH_URLS = [
         "/merchants/merchantuser/panel/login",
         "/merchants/merchantuser/panel/requestPasswordReset",
         "/merchants/merchantuser/panel/validatePasswordReset",
         "/merchants/merchantuser/panel/processPasswordReset",
         "/self-registration/self-registration-request/validateConfirmationMail/",
         "/merchants/panel/info",
         "/merchants/panel/ip-list/validate",
         "/merchants/merchantuser/panel/request2FAReset"
];


export const DOMAIN_INFO_KEY = "domain-config";
export const AUTHENTICATED_USER = "authenticatedUser";
export const ORDER_LIMITS = "ORDER_LIMITS";
export const BUSINESS_INFO = "businessInfo";
export const PAYMENT_INFO = "payment-info";
export const WALLET_INFO = "walletInfo";
export const ASK_REFLESH_TOKEN_PERIOD = "ASK_REFLESH_TOKEN_PERIOD";


export const MIN_DEPOSIT_AMOUNT_BY_COIN = [
         {
           code: "BTC",
           minimumDepositAmount: 0.001
         },
         {
           code: "ETH",
           minimumDepositAmount: 0.00856
         },
         {
           code: "XRP",
           minimumDepositAmount: 0.1
         },
         {
           code: "LTC",
           minimumDepositAmount: 0.001
         },
         {
           code: "BCH",
           minimumDepositAmount: 0.001
         }
       ];

export const REFUND_MESSAGES = [
         {
           code: "OVER_PAYMENT",
           message: "You overpaid the required amount."
         },
         {
           code: "UNDER_PAYMENT",
           message: "You underpaid the required amount."
         },
         {
           code: "DEPOSIT_AFTER_TIMEOUT",
           message: "You paid the required amount after time out."
         },
         {
           code: "DEPOSIT_AFTER_EXPIRE",
           message: "You paid the required amount after expire time"
         },
         {
           code: "NO_CRYPTO_CONVERSION",
           message: "You didn't select coin for payment"
         },
         {
           code: "NO_DEPOSIT",
           message:
             "You didn't send the required coin amount within required time."
         },
         {
           code: "SUFFICENT_AMOUNT",
           message: "Sufficent amount"
         }
       ];
