import Vue from "vue";
import Router from "vue-router";
import CheckAuth from "./middleware/CheckAuth";
import store from "../store";
import {
  getAPIURLInfo,
  getDomainInfoByCache,
  getAuthenticatedUserByCache
} from "@/helpers";

Vue.use(Router);

const domainByCache = getDomainInfoByCache();
const pageConfig = domainByCache ? domainByCache.page_config : {};

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Overview",
      component: () => import("../pages/dashboard/Overview.vue"),
      meta: {
        onlyAuthUser: true,
        layout: "default"
      },
      beforeEnter: CheckAuth,
    },
    {
      path: "/onboarding",
      name: "Onboarding",
      component: () => import("../pages/dashboard/Onboarding.vue"),
      meta: {
        onlyAuthUser: true,
        layout: "default",
        isDisabled: `${
          pageConfig.password_reset_disabled || (localStorage.getItem('dismissOnboarding') == undefined || localStorage.getItem('dismissOnboarding') == null || localStorage.getItem('dismissOnboarding') == 'true') ? true : false
        }`
       },
      beforeEnter: CheckAuth,
    },
    {
      path: "/orders",
      name: "Orders",
      component: () => import("../pages/orders/OrderList.vue"),
      meta: {
        onlyAuthUser: true,
        layout: "default",
      },
      beforeEnter: CheckAuth,
    },
    {
      path: "/order-detail/:id",
      name: "OrderDetail",
      component: () => import("../pages/orders/OrderDetail.vue"),
      meta: {
        onlyAuthUser: true,
        layout: "default",
      },
      beforeEnter: CheckAuth,
    },
    {
      path: "/trade-coin",
      name: "TradeCoin",
      component: () => import("../pages/trade-coin/TradeCoinBalance.vue"),
      meta: {
        onlyAuthUser: true,
        layout: "default",
        roles: ["TRADE_COIN"],
      },
      beforeEnter: CheckAuth,
    },
    {
      path: "/mass-payout",
      name: "massPayout",
      component: () => import("../pages/mass-payout/MassPayout.vue"),
      meta: {
        onlyAuthUser: true,
        layout: "default",
        roles: ["MASS_PAYOUT"],
      },
      beforeEnter: CheckAuth,
    },
    {
      path: "/mass-payout-detail",
      name: "massPayoutDetail",
      component: () => import("../pages/mass-payout/MassPayoutDetail.vue"),
      meta: {
        onlyAuthUser: true,
        layout: "default",
        roles: ["MASS_PAYOUT"],
      },
      beforeEnter: CheckAuth,
    },
    {
      path: "/earn",
      name: "Earn",
      component: () => import("../pages/earn/EarnList.vue"),
      meta: {
        onlyAuthUser: true,
        layout: "default",
        roles: ["EARN_MANAGEMENT"],
      },
      beforeEnter: CheckAuth,
    },
    {
      path: "/earn/activities",
      name: "EarnActivities",
      component: () => import("../pages/earn/Activities.vue"),
      meta: {
        onlyAuthUser: true,
        layout: "default",
        roles: ["EARN_MANAGEMENT"],
      },
      beforeEnter: CheckAuth,
    },
    {
      path: "/deposit-money",
      name: "DepositMoney",
      component: () => import("../pages/deposit-money/DepositMoney.vue"),
      meta: {
        onlyAuthUser: true,
        layout: "default",
        isDisabled: `${
          pageConfig ? pageConfig.deposit_money_page_disabled : true
        }`,
      },
      beforeEnter: CheckAuth,
    },
    {
      path: "/withdraw-money",
      name: "WithdrawMoney",
      component: () => import("../pages/withdraw-money/WithdrawMoney.vue"), //
      meta: {
        onlyAuthUser: true,
        layout: "default",
        roles: ["WITHDRAW_REQUEST_MANAGEMENT"],
        isDisabled: `${
          pageConfig ? pageConfig.withdraw_money_page_disabled : true
        }`,
      },
      beforeEnter: CheckAuth,
    },
    {
      path: "/withdraw-confirmation/:id",
      name: "WithdrawConfirmation",
      component: () =>
        import("../pages/withdraw-money/WithdrawConfirmation.vue"),
      meta: {
        onlyAuthUser: true,
        layout: "default",
        roles: ["WITHDRAW_REQUEST_MANAGEMENT"],
        isDisabled: `${
          pageConfig ? pageConfig.withdraw_money_page_disabled : true
        }`,
      },
      beforeEnter: CheckAuth,
    },
    {
      path: "/create-withdraw",
      name: "CreateWithdraw",
      component: () => import("../pages/withdraw-money/CreateWithdraw.vue"),
      meta: {
        onlyAuthUser: true,
        layout: "default",
        roles: ["WITHDRAW_REQUEST_MANAGEMENT"],
        isDisabled: `${
          pageConfig ? pageConfig.withdraw_money_page_disabled : true
        }`,
      },
      beforeEnter: CheckAuth,
    },
    {
      path: "/update-bank/:id",
      name: "UpdateBank",
      component: () => import("../pages/bank-details/UpdateBank.vue"),
      meta: {
        onlyAuthUser: true,
        layout: "default",
        roles: ["BANK_ACCOUNT_MANAGEMENT"],
        isDisabled: `${
          pageConfig ? pageConfig.bank_account_page_disabled : true
        }`,
      },
      beforeEnter: CheckAuth,
    },
    {
      path: "/create-bank",
      name: "CreateBank",
      component: () => import("../pages/bank-details/CreateBank.vue"),
      meta: {
        onlyAuthUser: true,
        layout: "default",
        roles: ["BANK_ACCOUNT_MANAGEMENT"],
        isDisabled: `${
          pageConfig ? pageConfig.bank_account_page_disabled : true
        }`,
      },
      beforeEnter: CheckAuth,
    },
    {
      path: "/bank-details",
      name: "BankDetails",
      component: () => import("../pages/bank-details/BankDetails.vue"),
      meta: {
        onlyAuthUser: true,
        layout: "default",
        roles: ["BANK_ACCOUNT_MANAGEMENT"],
        isDisabled: `${
          pageConfig ? pageConfig.bank_account_page_disabled : true
        }`,
      },
      beforeEnter: CheckAuth,
    },
    {
      path: "/update-user/:id",
      name: "UpdateUser",
      component: () => import("../pages/users/UpdateUser.vue"),
      meta: {
        onlyAuthUser: true,
        layout: "default",
        roles: ["USER_MANAGEMENT_ACTIONS"],
      },
      beforeEnter: CheckAuth,
    },
    {
      path: "/create-user",
      name: "CreateUser",
      component: () => import("../pages/users/CreateUser.vue"),
      meta: {
        onlyAuthUser: true,
        layout: "default",
        roles: ["USER_MANAGEMENT_ACTIONS"],
      },
      beforeEnter: CheckAuth,
    },
    {
      path: "/user-logs",
      name: "UserLogs",
      component: () => import("../pages/users/UserLogs.vue"),
      meta: {
        onlyAuthUser: true,
        layout: "default",
        roles: ["DISPLAY_USER_LOGS"],
      },
      beforeEnter: CheckAuth,
    },
    {
      path: "/users",
      name: "users",
      component: () => import("../pages/users/UserList.vue"),
      meta: {
        onlyAuthUser: true,
        layout: "default",
        roles: ["USER_MANAGEMENT_ACTIONS"],
      },
      beforeEnter: CheckAuth,
    },
    {
      path: "/make-payment",
      name: "MakePayment",
      component: () => import("../pages/payment/Payment.vue"),
      meta: { onlyAuthUser: true, layout: "default", roles: ["MAKE_PAYMENT"] },
      beforeEnter: CheckAuth,
    },
    {
      path: "/receive-payment",
      name: "ReceivePayment",
      component: () => import("../pages/payment/ReceivePayment.vue"),
      meta: {
        onlyAuthUser: true,
        layout: "default",
        roles: ["RECEIVE_PAYMENT"],
      },
      beforeEnter: CheckAuth,
    },
    {
      path: "/receive-deposit",
      name: "ReceiveDeposit",
      component: () => import("../pages/receive-deposit/ReceiveDeposit.vue"),
      meta: {
        onlyAuthUser: true,
        layout: "default",
        roles: ["DEPOSIT"],
      },
      beforeEnter: CheckAuth,
    },
    {
      path: "/payment-success",
      name: "PaymentSuccess",
      component: () => import("../pages/payment/Success.vue"),
      meta: { onlyAuthUser: true, layout: "default", roles: ["MAKE_PAYMENT"] },
      beforeEnter: CheckAuth,
    },
    {
      path: "/trade-coin-buy",
      name: "TradeCoinBuy",
      component: () => import("../pages/trade-coin/buy-sell/BuyTradeCoin.vue"),
      props: true,
      meta: {
        onlyAuthUser: true,
        layout: "default",
        roles: ["TRADE_COIN"],
      },
      beforeEnter: CheckAuth,
    },
    {
      path: "/trade-coin-sell",
      name: "TradeCoinSell",
      component: () => import("../pages/trade-coin/buy-sell/SellTradeCoin.vue"),
      props: true,
      meta: {
        onlyAuthUser: true,
        layout: "default",
        roles: ["TRADE_COIN"],
      },
      beforeEnter: CheckAuth,
    },
    {
      path: "/trade-coin-buy-sell-success",
      name: "TradeCoinBuySellSuccess",
      component: () =>
        import("../pages/trade-coin/buy-sell/BuySellTradeCoinSuccess.vue"),
      props: true,
      meta: {
        onlyAuthUser: true,
        layout: "default",
        roles: ["TRADE_COIN"],
      },
      beforeEnter: CheckAuth,
    },
    {
      path: "/trade-coin-withdraw",
      name: "TradeCoinWithdraw",
      component: () =>
        import("../pages/trade-coin/withdraw/WithdrawTradeCoin.vue"),
      props: true,
      meta: {
        onlyAuthUser: true,
        layout: "default",
        roles: ["TRADE_COIN"],
      },
      beforeEnter: CheckAuth,
    },
    {
      path: "/trade-coin-withdraw-success",
      name: "TradeCoinWithdrawSuccess",
      component: () =>
        import("../pages/trade-coin/withdraw/WithdrawTradeCoinSuccess.vue"),
      props: true,
      meta: {
        onlyAuthUser: true,
        layout: "default",
        roles: ["TRADE_COIN"],
      },
      beforeEnter: CheckAuth,
    },
    {
      path: "/trade-coin-deposit",
      name: "TradeCoinDeposit",
      component: () =>
        import("../pages/trade-coin/deposit/DepositTradeCoin.vue"),
      props: true,
      meta: {
        onlyAuthUser: true,
        layout: "default",
        roles: ["TRADE_COIN"],
      },
      beforeEnter: CheckAuth,
    },
    {
      path: "/auth/login",
      name: "Login",
      component: () => import("../pages/auth/Login.vue"),
      meta: { layout: "auth" },
    },
    {
      path: "/forgot-password",
      name: "ForgotPassword",
      component: () => import("../pages/auth/ForgotPassword.vue"),
      meta: { layout: "auth" },
    },
    {
      path: "/reset-password-confirmation",
      name: "Reset Password",
      component: () => import("../pages/auth/ResetPassword.vue"),
      meta: { layout: "auth" },
    },
    {
      path: "/new-ip-device",
      name: "New Ip Device",
      component: () => import ("../pages/auth/NewIpDevice.vue"),
      meta: { layout: "auth" }
    },
    {
        path: "/verify-ip-device",
        name: "Verify New IP Device",
        component: () => import ("../pages/auth/VerifyNewIpDevice.vue"),
        meta: { layout: "auth" }
    },
    {
      path: "/developer-hub",
      name: "DeveloperHub",
      meta: {
        isDisabled: `${pageConfig ? pageConfig.api_doc_disabled : true}`,
      },
      beforeEnter() {
        store.dispatch('user/apiDoc')
        .then((res) => {
          const authUrl = res.data.authUrl;
          window.open(authUrl);
        })
        .catch(err => {
          if (process.env.NODE_ENV === "development") {
            window.console.log(err)
          }
        })
      },
    },
    {
      path: "/crypto-asset-catalogue",
      name: "cryptoAssetCatalogue",
      beforeEnter() {
        window.open('https://www.bitpace.com/blog/crypto-asset-catalogue/');
      },
    },
    {
      path: "/plugins",
      name: "Plugins",
      meta: {
        isDisabled: `${pageConfig ? pageConfig.plugins_page_disabled : true}`,
      },
      beforeEnter() {
        window.open(`${getAPIURLInfo().pluginsPageUrl}`);
      },
    },
    {
      path: "/support",
      name: "Support",
      component: () => import("../pages/support/Support.vue"),
      meta: {
        onlyAuthUser: true,
        layout: "default",
        roles: ["DISPLAYING_SUPPORT"],
        isDisabled: `${pageConfig ? pageConfig.support_page_disabled : true}`,
      },
      beforeEnter: CheckAuth,
    },
    {
      path: "/support/success",
      name: "SupportSuccess",
      component: () => import("../pages/support/Success.vue"),
      meta: {
        onlyAuthUser: true,
        layout: "default",
        roles: ["DISPLAYING_SUPPORT"],
      },
      beforeEnter: CheckAuth,
    },
    {
      path: "/unauthenticated",
      name: "PageNotAuthenticated",
      component: () => import("../pages/auth/PageNotAuthenticated.vue"),
      meta: { layout: "auth", onlyAuthUser: false },
      beforeEnter: CheckAuth,
    },
    {
      path: "/unauthorized",
      name: "PageNotAuthorized",
      component: () => import("../pages/auth/PageNotAuthorized.vue"),
      meta: { layout: "auth", onlyAuthUser: false },
    },
    {
      path: "*",
      name: "PageNotFound",
      component: () => import("../pages/general/PageNotFound.vue"),
      meta: { layout: "auth", onlyAuthUser: false },
    },
    {
      path: "/payment-setting",
      name: "Payment Settings",
      component: () => import("../pages/account-settings/PaymentSetting.vue"),
      meta: { onlyAuthUser: true, layout: "default", roles: ["PAYMENT_SETTINGS"], },
    },
    {
      path: "/account-statements",
      name: "AccountStatements",
      component: () => import("../pages/account-settings/AccountStatements.vue"),
      meta: { 
        onlyAuthUser: true, 
        layout: "default", 
        roles: ["VIEW_STATEMENTS"], 
      },
    },
    {
      path: "/auto-conversion",
      name: "AutoConversion",
      component: () => import("../pages/account-settings/AutoConversion.vue"),
      meta: { onlyAuthUser: true, layout: "default",  roles: ["AUTO_CONVERSION"] },
    },
    {
      path: "/information",
      name: "Information",
      component: () => import("../pages/account-settings/Information.vue"),
      meta: { onlyAuthUser: false, layout: "default" },
    },
    {
      path: "/rates-fees-limits",
      name: "RatesFeesLimits",
      component: () => import("../pages/account-settings/RatesFeesLimits.vue"),
      meta: { onlyAuthUser: false, layout: "default" },
    },
    {
      path: "/currencies",
      name: "Currencies",
      component: () => import("../pages/account-settings/Currencies.vue"),
      meta: { 
        onlyAuthUser: true,
        layout: "default",
        roles: ["CURRENCY_MANAGEMENT"],
       },
    },
    {
      path: "/api/management",
      name: "ApiManagement",
      component: () => import("../pages/api/Management.vue"),
      meta: {
        onlyAuthUser: true,
        layout: "default",
        roles: ["API_MANAGEMENT"],
        isDisabled: `${pageConfig ? pageConfig.api_doc_disabled : false}`,
      },
      beforeEnter: CheckAuth,
    },
    {
      path: "/auth/2fa-enable",
      name: "2FaEnable",
      component: () => import("../pages/auth/2faEnable.vue"),
      meta: { onlyAuthUser: true, layout: "auth" },
    },
    {
      path: "/auth/2fa-verify",
      name: "2FaVerify",
      component: () => import("../pages/auth/2faVerify.vue"),
      meta: { onlyAuthUser: true, layout: "auth" },
    },
    {
      path: "/auth/2fa-reset",
      name: "2FaReset",
      component: () => import("../pages/auth/2faReset.vue"),
      meta: { onlyAuthUser: false, layout: "auth" },
    },
    {
      path: "/auth/reset-2fa-verify",
      name: "2FaResetVerify",
      component: () => import("../pages/auth/2faResetVerify.vue"),
      meta: { onlyAuthUser: false, layout: "auth" },
    },
    {
      path: "/sub-accounts",
      name: "SubAccounts",
      component: () => import("../pages/SubAccounts.vue"),
      meta: {
        onlyAuthUser: true,
        layout: "default"
      },
      beforeEnter: CheckAuth,
    }
  ],
});

async function getDomainInfo() {
  await store
    .dispatch("merchant/getDomainInfo", {
      domain_url: getAPIURLInfo().hostName
    })
    .then(() => {})
    .catch(err => {
      if (process.env.NODE_ENV === "development") {
       window.console.log(err);
      }
    });
}

router.beforeEach(async (to, from, next) => {

  let authenticatedUser = getAuthenticatedUserByCache();
  if (!domainByCache && to.name == 'Login') {
    await getDomainInfo();
  }

  if (
    to.matched.some(
      record => record.meta.onlyAuthUser && !(record.meta.isDisabled == "true")
    )
  ) {
    if (authenticatedUser) {
      const { roles } = to.meta;
      const { isDisabled } = to.meta;
      const userRoles = authenticatedUser.roles;
      if (
        userRoles &&
        roles &&
        roles.length &&
        userRoles.length &&
        !roles.every(r => userRoles.includes(r))
      ) {
        return next({ path: "/unauthorized" });
      }

      if (isDisabled == "true") {
        return next({ path: "/unauthorized" });
      }
      return next();
    } else {        
      return next("/auth/login");
    }
  } else {
    if (authenticatedUser) {
      const { roles } = to.meta;
      const { isDisabled } = to.meta;
      const userRoles = authenticatedUser.roles;
      if (
        userRoles &&
        roles &&
        roles.length &&
        userRoles.length &&
        !roles.every(r => userRoles.includes(r))
      ) {
        return next({ path: "/unauthorized" });
      }

      if (isDisabled == "true") {
        return next({ path: "/unauthorized" });
      }
      return next();
    } else {
      return next();
    }
  }
});

export default router;
