<template>
  <div :class="headerbg" class="app-header header-shadow">
    <a :href="redirectUrl" class="logo-src">
      <img class="logo-default" :src="logoUrl" height="25px" alt="" />
    </a>
    <div class="app-header__content">
      <div class="app-header-left">
        <HeaderBalance />
      </div>
      <div class="app-header-right">
        <UserArea />
      </div>
    </div>
    <div class="app-header__mobile-menu">
      <div>
        <button
          type="button"
          class="hamburger close-sidebar-btn hamburger--elastic"
          v-bind:class="{ 'is-active': isOpen }"
          @click="toggleMobile('closed-sidebar-open')"
        >
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>
      </div>
    </div>
    <b-modal
      v-model="showSessionModal"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      hide-header
      hide-footer
    >
      <div>
        <LoginConfirmModal
          v-if="showSessionModal"
          @setRemain="setRemainModal"
        />
      </div>
    </b-modal>
    <div class="app-header__menu">
      <!-- <span>
        <b-button
          class="btn-icon btn-icon-only"
          variant="primary"
          size="sm"
          v-bind:class="{ 'active' : isOpenMobileMenu }"
          @click="toggleMobile2('header-menu-open')"
        >
          <div class="btn-icon-wrapper">
            <font-awesome-icon icon="ellipsis-v" />
          </div>
        </b-button>
      </span> -->
    </div>
  </div>
</template>

<script>
import UserArea from "./header/HeaderUserArea";
import HeaderBalance from "./header/HeaderBalance";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { getAPIURLInfo, getDomainInfoByCache } from "@/helpers";
import LogoutModal from "@/components/popup/LogoutModal.vue";
import LoginConfirmModal from "@/components/popup/LoginConfirmModal.vue";

library.add(faEllipsisV);

export default {
  name: "Header",
  components: {
    LoginConfirmModal,
    LogoutModal,
    UserArea,
    HeaderBalance,
  },
  data() {
    return {
      showSessionModal: false,
      interval: null,
      isOpen: false,
      isLoading: false,
      isOpenMobileMenu: false,
      redirectUrl: '/',
      logoUrl: null,
      domainInfo: getDomainInfoByCache()
    };
  },
  props: {
    headerbg: String
  },
  methods: {
    toggleMobile(className) {
      const el = document.body;
      this.isOpen = !this.isOpen;

      if (this.isOpen) {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
    setRemainModal(status) {
      if (status) {
        localStorage.setItem('modalState', 'hidden');

        const IDLETIME = parseInt(process.env.VUE_APP_IDLE_TIME, 10);
        const currentTime = Date.now();

        localStorage.setItem('startTime', currentTime);
        localStorage.setItem('duration', IDLETIME);

        if (localStorage.getItem('remainTime')) {
          localStorage.removeItem('remainTime');
        }

        clearInterval(this.interval);
        this.interval = setInterval(() => {
          this.updateRemainingTime();
        }, 1000);

        this.updateModalVisibility();
      }
    },
    updateRemainingTime() {
      const calculateRemainingTime = () => {
        const startTime = parseInt(localStorage.getItem('startTime'), 10);
        const duration = parseInt(localStorage.getItem('duration'), 10);

        if (isNaN(startTime) || isNaN(duration)) return 0;

        const elapsedTime = Math.floor((Date.now() - startTime) / 1000);
        return Math.max(duration - elapsedTime, 0);
      };

      const remainingTime = calculateRemainingTime();

      if (remainingTime <= 0) {
        localStorage.setItem('modalState', 'shown');
        this.updateModalVisibility();
        clearInterval(this.interval);

        localStorage.removeItem('remainTime');
        localStorage.removeItem('startTime');
        localStorage.removeItem('duration');
      } else {
        localStorage.setItem('remainTime', remainingTime);
      }
    },
    updateModalVisibility() {
      // Determine which tab should show the modal
      const modalState = localStorage.getItem('modalState');
      this.showSessionModal = modalState === 'shown';
    },
    handleStorageEvent(event) {
      // Only handle modalState changes from other tabs
      if (event.key === 'modalState') {
        this.updateModalVisibility();
      }
      // Update remaining time if relevant changes occur
      if (event.key === 'remainTime' || event.key === 'startTime' || event.key === 'duration') {
        this.updateRemainingTime();
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.interval);
    window.removeEventListener('storage', this.handleStorageEvent);
  },
  created() {
    this.logoUrl = getAPIURLInfo().defaultLogoUrlBlack;
    this.$store.dispatch('content/getCryptoCurrencies')
    // Ensure startTime and duration are set
    if (!localStorage.getItem('startTime') || !localStorage.getItem('duration')) {
      const IDLETIME = parseInt(process.env.VUE_APP_IDLE_TIME, 10);
      localStorage.setItem('startTime', Date.now());
      localStorage.setItem('duration', IDLETIME);
    }

    // Start the interval to update the remaining time every second
    this.interval = setInterval(this.updateRemainingTime, 1000);

    // Listen for storage events to synchronize remaining time and modal state across tabs
    window.addEventListener('storage', this.handleStorageEvent);

    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        this.updateRemainingTime();
        this.updateModalVisibility();
      }
    });

    // Initial update of the remaining time and modal visibility
    this.updateRemainingTime();
    this.updateModalVisibility();
  }
};
</script>

<style scoped>
.logo-small-default {
  display: none;
}

.closed-sidebar .logo-small-default {
  display: block;
  width: 35px;
}
.closed-sidebar-open .logo-small-default {
  display: none;
}

.closed-sidebar-open .logo-default {
  display: block;
  height: 30px;
}
</style>
